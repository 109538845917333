var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        [
          _c(
            "card-layout",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function() {
                    return [
                      _vm._v(" Device Info "),
                      _c("span", [_vm._v(_vm._s(_vm.deviceId))])
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("GrafanaEmbeddedDashboard", {
                attrs: {
                  src: _vm.urlPath,
                  annotations: "1",
                  selected: _vm.selectedDevice,
                  mode: true,
                  kiosk: "",
                  truckID: _vm.deviceId
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }