<template>
  <CRow>
    <CCol>
      <card-layout>
        <template #header>
          Device Info <span>{{ deviceId }}</span>
        </template>
        <GrafanaEmbeddedDashboard
        :src="urlPath"
        
        annotations="1"
        
        :selected="selectedDevice"
        
        :mode="true"
        kiosk=""

        :truckID="deviceId"
      />
      </card-layout>
        
    </CCol>
  </CRow>
</template>

<script>
import { faTrophy } from "@fortawesome/free-solid-svg-icons";

import GrafanaEmbeddedDashboard from "@/components/grafana/GrafanaEmbeddedDashboard";
import BaseGrafanaDashboard from "@/components/grafana/BaseGrafanaDashboard";
import CardLayout from '@/layouts/CardLayout.vue';

const diagnostics="https://cleanharbors-lonestar.aarcomm.io/api/grafana/d/c9KBefa4k/diagnostics-demo-v1?orgId=6";


export default {
  name: "DeviceManagementView",
  components: {
    GrafanaEmbeddedDashboard,
    BaseGrafanaDashboard,
    CardLayout,
  },
  data() {
    return {
      selectedDevice: undefined,
      selectedDashboard: undefined,
      _urlPath: undefined,
      range: {
        from: Date.now() - (3600*1000*24)*2,
        to: Date.now(),
      },
    };
  },
  computed: {

    deviceId(){
      return this.selectedDevice?this.selectedDevice.device:""; 
    },
    urlPath(){

      return diagnostics; 
      if(!this.selectedDevice)  return ; 
      let temp = this.selectedDashboard; 
       //If Not Found in user Database OR value is Undefined, use default 
      if(!temp || !temp.url){
        temp = defaultHistoricalDashboard; 
        temp += "&var-TruckID=" + this.device.device;
        return temp; 
      }
         //temp = temp.value; 
         return temp.url; 
    }
  },
  methods: {
    async load(params) {
      try {
        console.log(this.$route);
        let id = this.$route.params.id;

        let inventory = await this.$app.loadInventory(); 

        console.log(inventory);

        

        this.selectedDevice =  inventory.find((item) => item.device === id);
        
      } catch (err) {
        console.log(err);
      }
      //console.log(this.selectedDashboard, this.selectedDevice);
    },
  },
  async mounted() {
    await this.$nextTick(()=>this.load());
  },
  async activated() {
    await this.$nextTick(()=>this.load());
  },
};
</script>